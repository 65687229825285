import select from 'dom-select'
import { gsap } from 'gsap'
import event from 'dom-event'
import config from '../config'
import bind from 'es6-class-bind-all'

class Nav {

    constructor () {

        bind(this)
        this.cache()
        this.init()

    }

    cache () {

        config.isTwo
            ? this.navigation = select('.nav_mob')
            : this.navigation = select('.nav_desk')

        this.burger = select('.burger')
        this.itemsNav = [...this.navigation.querySelectorAll('.nav_item_anchor')]

    }

    init () {

        this.addEvents('on')

    }

    addEvents (type) {

        console.log('configistwo', config.isTwo)

        config.isTwo && event[type](this.burger, 'click', () => this.toggleNavigation(this))

        config.isTwo && this.itemsNav.forEach((item) => {

            event[type](item, 'click', () => this.closeNavigation(this))

        })

    }

    closeNavigation () {

        const bNav = select('.b-container')
        this.navigation.classList.remove('open')
        this.burger.classList.remove('open')
        select('body').classList.remove('nav_mob_open')
        bNav.classList.remove('open')
        select('.footer.mob-flex').classList.remove('open')
        gsap.from(select('.footer.mob-flex .mono svg'), {
            duration: 0.8,
            rotationY: 270
        })

    }

    openNavigation () {

        const bNav = select('.b-container')
        this.burger.classList.add('open')
        select('body').classList.add('nav_mob_open')
        bNav.classList.add('open')
        this.navigation.classList.add('open')
        select('.footer.mob-flex').classList.add('open')
        gsap.from(select('.footer.mob-flex .mono svg'), {
            duration: 0.8,
            rotationY: 270,
        })

    }

    toggleNavigation () {

        const open = this.navigation.classList.contains('open')

        if( open ) {

            this.closeNavigation()

        } else {

            this.openNavigation()

        }

    }

}

export default Nav
