import Nav from '../components/Nav'
import Home from '../sections/home'
import Preloader from '../components/preloader'

export default class Framework {
	constructor() {
		this.init()
	}
	
	init() {

		new Preloader
		new Home
		new Nav

	}
}