import es6ClassBindAll from 'es6-class-bind-all'
import select from 'dom-select'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import event from 'dom-event'
import config from '../config'
import configQ from '../util/func/configQ'
import LocomotiveScroll from 'locomotive-scroll'
import inView from 'in-view'

class Home {

    constructor () {

        es6ClassBindAll(this)
        this.mounted()

    }

    mounted () {

        this.cache()
        this.init()

    }

    cache () {

        configQ(window.innerWidth)
        !config.isTwo && gsap.registerPlugin(ScrollTrigger)
        gsap.registerPlugin(ScrollToPlugin)

        this.itemMonitor = [...document.querySelectorAll('.monitor_item')]

        config.isTwo
            ? this.navigation = select('.nav_mob')
            : this.navigation = select('.nav_desk')

        this.itemsNav = [...this.navigation.querySelectorAll('.nav_item_anchor')]

    }

    init () {

        this.addEventsDef()
        // this.quidInsta()
        if (!config.isTwo) {

            this.setPositions()
            const int = setInterval(() => {

                if (select('body').classList.contains('ready')) {

                    this.initLoco()
                    clearInterval(int)

                }

            }, 150)

        } else {

            const int2 = setInterval(() => {

                this.setHeightMobile()
                if (select('body').classList.contains('ready')) {

                    this.initMonique()
                    clearInterval(int2)

                }

            }, 150)
            setTimeout(() => {

                this.setHeightMobile()

            }, 100)

            // this.initMonique()

        }

    }

    initMonique () {

        inView.offset(50)
        inView('.monitor_item')
            .on('enter', e => e.classList.add('is-inview'))

        this.addEventsScroller()

    }

    initLoco () {

        const widthRest = window.innerWidth
        const pageContainer = document.querySelector('[data-scroll-container]')

        const base = select('.base')
        const widthBase = base.offsetWidth / 2
        base.style.left = `${(widthRest / 2) - (widthBase)}px`

        const scroller = new LocomotiveScroll({
            el: pageContainer,
            smooth: true,
            direction: 'horizontal',
            gestureDirection: 'both',
            tablet: {
                smooth: true,
                direction: 'vertical'
            },
            smartphone: {
                smooth: false
            },
            reloadOnContextChange: true,
            rest: document.querySelector('#restaurant .wrapper_text'),
            restContent: document.querySelector('#restaurant .wrapper_text .content_text'),
            wh1: document.querySelector('#restaurant .white_space'),
            che: document.querySelector('#chef .wrapper_text'),
            wh2: document.querySelector('.wrap3'),
            ca: document.querySelector('#carte .wrapper_text'),
            wh5: document.querySelector('.wrap5'),
            wh4: document.querySelector('.wrap4')
        })

        setTimeout(() => {

            select('#restaurant .content_text').classList.remove('is-inview')
            select('#restaurant .white_space').classList.remove('is-inview')

        }, 500)

        this.addEventsScroller(scroller)

        scroller.on('scroll', (e) => {

            ScrollTrigger.update()
            scroller.rest.setAttribute('data-scroll-offset', '100%, 100%')
            // scroller.rest.setAttribute('data-scroll-offset', `0, ${-widthRest}`)
            // scroller.wh1.setAttribute('data-scroll-offset', `${widthRest}, ${-widthRest}`)
            // scroller.che.setAttribute('data-scroll-offset', `0, ${-widthRest / 8}`)
            // scroller.wh2.setAttribute('data-scroll-offset', `0, ${-widthRest / 3}`)
            // scroller.ca.setAttribute('data-scroll-offset', `0, ${-widthRest * 1.5}`)
            // scroller.wh4.setAttribute('data-scroll-offset', `0, ${-widthRest * 1.5}`)
            // scroller.wh5.setAttribute('data-scroll-offset', `0, ${-widthRest * 1.5}`)
            // scroller.wh5.setAttribute('data-scroll-offset', `0, ${-widthRest * 1.5}`)

            Object.values(e.currentElements).forEach((el) => {

                if (el.el.localName === 'section') {

                    const id = el.el.id
                    id !== 'restaurant' && this.selectNavItem(id)

                }

                el.el.id === 'restaurant-text' && this.selectNavItem('restaurant')

            })

        })

        ScrollTrigger.scrollerProxy(
            '.container', {
                scrollLeft (value) {

                    return arguments.length
                        ? scroller.scrollTo(value, 0, 0)
                        : scroller.scroll.instance.scroll.x

                },
                scrollTop (value) {

                    return arguments.length
                        ? scroller.scrollTo(value, 0, 0)
                        : scroller.scroll.instance.scroll.x

                },
                getBoundingClientRect () {

                    return {
                        left: 0,
                        top: 0,
                        width: window.innerWidth,
                        height: window.innerHeight
                    }

                },
                pinType: pageContainer.style.transform ? 'transfor' : 'fixed'

            }
        )

        ScrollTrigger.defaults({
            scroller: '.container'
        })

        setTimeout(() => {

            ScrollTrigger.create({
                trigger: '.wrap1',
                scroller: '.container',
                animation: gsap.to('.wrap1', { backgroundPositionY: '70%' }),
                scrub: 2
            })

            ScrollTrigger.create({
                trigger: '.wrap2',
                scroller: '.container',
                animation: gsap.to('.wrap2', { backgroundPositionY: '20%' }),
                scrub: 2
            })

        }, 200)

        gsap.to('.wrap3', {
            scrollTrigger: {
                trigger: '#chef',
                scroller: '.container',
                scrub: 2,
                start: 'top+=600% bottom+=600',
                end: 'top+=600% top+=600'
            },
            backgroundPositionY: '75%',
            ease: 'none'

        })

        gsap.from('.wrap6', {
            scrollTrigger: {
                trigger: '#cuisine',
                scroller: '.container',
                scrub: 2,
                start: 'top+=750% bottom+=750',
                end: 'top+=750%'
            },
            backgroundPositionY: '20%',
            ease: 'none'
        })

        gsap.from('.wrap4', {
            scrollTrigger: {
                trigger: '#carte',
                scroller: '.container',
                scrub: 2,
                start: 'top+=950% bottom+=950',
                end: 'top+=950%'
            },
            backgroundPositionY: '100%',
            ease: 'none'
        })

        gsap.from('.wrap5', {
            scrollTrigger: {
                trigger: '#carte',
                scroller: '.container',
                scrub: 2,
                start: 'top+=950% bottom+=950',
                end: 'top+=950%'
            },
            backgroundPositionY: '0%',
            ease: 'none'
        })

        ScrollTrigger.addEventListener('refresh', () => scroller.update())

        ScrollTrigger.refresh()

        setTimeout(() => scroller.update(), 500)

        event['on'](window, 'resize', () => ScrollTrigger.refresh())

    }

    setPositions () {

        const wrappers = [...document.querySelectorAll('.wrapper_image')]
        wrappers.forEach((wrapper) => {

            const positionX = wrapper.getBoundingClientRect().left
            const positionY = wrapper.getBoundingClientRect().top

            wrapper.style.setProperty('--position_y', `${positionY}px`)
            wrapper.style.setProperty('--position_x', `${positionX}`)

        })

    }

    selectNavItem (id) {

        const navSelect = select(`.nav_item[data-id="${id}"]`)
        const oldSelect = select('.select')

        if (navSelect !== oldSelect) {

            oldSelect && oldSelect.classList.remove('select')
            navSelect && navSelect.classList.add('select')

        }

    }

    addEventsScroller (scroller) {

        this.itemsNav.forEach((item) => {

            event['on'](item, 'click', () => {

                const id = item.dataset.id
                const section = select(`#${id}`)

                console.log('item clicked', item)
                if (config.isTwo) {

                    gsap.to(window, { duration: 0.3, ease: 'power2.out', scrollTo: { y: section, offsetY: 30 } })

                } else {

                    scroller.scrollTo(section)

                }

            })

        })

    }

    addEventsDef () {

        event['on'](window, 'resize', this.onResize)

    }

    setHeightMobile () {

        const vh = window.innerHeight * 0.01
        select('html').style.setProperty('--vh', `${vh}px`)

    }

    quidInsta () {

        innerHeight < 821
        ? document.querySelector('.insta_part').style.display = 'none'
        : document.querySelector('.insta_part').style.display = 'auto'
    }

    onResize (def) {

        config.isTwo && this.setHeightMobile()
        console.log('resize')
        // this.quidInsta()

    }

}

export default Home
