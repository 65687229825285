import select from 'dom-select'
import { gsap } from "gsap"
import event from 'dom-event'
import config from '../config'
import bind from 'es6-class-bind-all'
import ImagePreloader from "image-preloader"
import PromiseDelay from "promise-ext-delay"
import remove from "dom-remove"

class Preloader {

    constructor () {

        bind(this)
        this.init()

    }

    init () {

        const topBand = gsap.utils.toArray(['.int1', '.int5', '.int9'])
        const botBand = gsap.utils.toArray(['.int3', '.int7'])
        const headeritems = gsap.utils.toArray(['.nav_desk .first_part', '.nav_desk .last_part', '.footer.desk .first_part', '.footer.desk .last_part'])

        const h = window.innerHeight


        if (!config.isTwo) {

            gsap.set(".preloader", {perspective:800})
            gsap.set(".mono", {transformStyle:"preserve-3d"})
    
            gsap.set(".nav_desk", {perspective:800})
            gsap.set(".nav_desk .logo_part", {transformStyle:"preserve-3d"})
            gsap.set(".nav_desk .logo_part svg", {transformOrigin: 'top top'})
    
            const td = gsap.timeline()
            .to(topBand, {
                duration: 0.5,
                y: 0,
            })
            .to(botBand, {
                duration: 0.5,
                y: 0
            })
            .to('.mono', {
                duration: 0.4,
                opacity: 1
            }, '+=0.1')
            .to('.mono svg', {
                duration: 1.4,
                rotationY: 1081,
                ease: "back.inOut(1.5)",
            }, '+=0.1')
            .call(() => {
                console.log('rea')
                select('body').classList.add('ready')
            })
            .to('.preloader', {
                duration: 1,
                y: h * -1,
                ease: "back.inOut(1.5)",
            })
            .from(headeritems, {
                duration: 0.4,
                opacity: 0
            },'-=0.2')
            .from('.base', {
                duration: 0.4,
                opacity: 0
            })
            .from('.nav_desk .logo_part svg', {
                duration: 0.8,
                rotationX: 90,
                y: 3,
                opacity: 0,
                ease: "power3.out",
            }, '-=0.4')

            td.call(() => {

                const preloaders = document.querySelectorAll('.preloader')
                preloaders.forEach((preloader) => {preloader.remove()})

            })

        } else {

            gsap.set(".preloader", {perspective:800})
            gsap.set(".mono", {transformStyle:"preserve-3d"})
    
            gsap.set(".nav_desk", {perspective:800})
            gsap.set(".nav_desk .logo_part", {transformStyle:"preserve-3d"})
            gsap.set(".nav_desk .logo_part svg", {transformOrigin: 'top top'})

            select('body').style.overflow = 'hidden'
    
            const td = gsap.timeline()
            .to('.int1', {
                duration: 0.5,
                y: 0,
            })
            .to('.int7', {
                duration: 0.5,
                y: 0,
            },'-=0.5')
            .to('.int3', {
                duration: 0.5,
                y: 0,
            }, '-=0.1')
            .to('.int5', {
                duration: 0.5,
                y: 0,
            },'-=0.5')
            .to('.preloader .mono', {
                duration: 0.4,
                opacity: 1
            }, '+=0.1')
            .to('.preloader .mono svg', {
                duration: 1.4,
                rotationY: 1081,
                ease: "back.inOut(1.5)",
            }, '+=0.1')
            .to('.preloader', {
                duration: 1,
                y: h * -1.5,
                ease: "back.inOut(1.5)",
            })
            .call(() => {
                select('body').classList.add('ready')
            })
            .from(headeritems, {
                duration: 0.4,
                opacity: 0
            },'-=0.5')
            .from('.nav_desk .logo_part svg', {
                duration: 0.8,
                rotationX: 90,
                 y: 3,
                opacity: 0,
                ease: "power3.out",
            }, '-=0.4')
            .call(() => {
                select('body').style.overflow = 'scroll'
            })
            .from('.wrap1', {
                duration: 3,
                backgroundPositionX: '0%',
                ease: "power3.out",
            }, '-=2')
            .from('.wrap2', {
                duration: 3,
                backgroundPositionX: '100%',
                ease: "power3.out",
            }, '-=3')
            .call(() => {
                const preloaders = document.querySelectorAll('.preloader')
                preloaders.forEach((preloader) => {preloader.remove()})
            })

        }
        
    }
}

export default Preloader